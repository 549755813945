import React from 'react';
import { Link } from 'react-router-dom';
import { animated } from '@react-spring/web';
import useAnimatedLine from '../animations/animatedLine.js';
import GiphyEmbed from '../animations/giphyEmbed.js'
import CheckpointButton from '../CheckpointButton.js';

function Chapitre7Saimer() {

  const [animationStyle1, ref1] = useAnimatedLine();
  const [animationStyle2, ref2] = useAnimatedLine();

  return (
      <>
        <h1 className='chapitre'>Chapitre 7: L'ennui et le défaussement de soi</h1>

        <p>
        Pourquoi ce titre “le défaussement de soi” ? Car on peut être amené à s'ignorer et à se mettre de côté à cause d'habitudes et de craintes.<br />
        La société actuelle n'arrange en rien nos objectifs de revenir à nous-même et de se sentir bien dans sa peau - dans sa vie. L'ambition de s'aimer est souvent quelque chose qui se perd à force d'être pris dans le circuit d'une vie usuelle. Les divertissements sont omniprésents et nous poussent à être stimulé uniquement dans un sens extérieur.
        <br /><br />
        </p>
        <animated.div className="animated-line" style={animationStyle1} ref={ref1}>
        <p className="animatable">
            Les réseaux sociaux conduisent les gens à se comparer, et à vivre au travers des vidéos sur la vie menée par d'autres personnes. On s'habitue à cette paresse au travers des technologies qui sont sensées nous aider plus que de nous faire régresser.
        </p>
        </animated.div>
        
        <p>
        L'ennui est quelque chose que l'on évite car on en a peur : le silence, le calme nous donne une sorte de connexion à nous-même, et c'est de ça qu'on a réellement peur.<br /><br />
        Le bruit permet aux gens de moins vivre ce “face à face” avec soi :  c'est plus confortable. D'ailleurs c'est quelque chose que j'aurais bien vu enseigné à l'école : pouvoir être ami avec l'ennui. Car cela permettrait aux enfants de comprendre très tôt qu'ils peuvent être à l'aise avec l'ennui, et au lieu de se fermer au monde intérieur, on saurait accepter de s'ouvrir à : ses idées, son imaginaire, ses ressentis ...
        </p>

        <p>
        Adulte, le fait même d'être face à soi-même devient parfois un défi, où l'on s'empresse de prendre la moindre distraction pour ne pas sentir le “mal-aise”.
        Ceci, alors que l'ennui est un ressenti important qui indique que nous devons nous ouvrir à de nouvelles choses (idées, activités, s'aérer l'esprit etc).
        </p>

        <animated.div className="animated-line" style={animationStyle2} ref={ref2}>
        <p className="animatable">
        S'aimer, c'est aussi être dans une maîtrise : la maîtrise émotionnelle et mentale qui nous permet d'être ce qu'on appelle “aligné” dans le jargon spirituel.
        </p>
        </animated.div>

        <p>
        La prochaine fois que je m'ennuie : je prends le temps de ressentir ce qu'il se passe dans mon corps. Je respire. Je dénoue les tensions ici et là (épaules, coudes, genou, front etc...).<br />
        Je regarde mes pensées à propos des solutions “confortables” qui me viennent car ça me presse peut-être à ne pas percevoir le fait qu'il y a autre chose que ce "confort" : certaines habitudes sont une facilité qui ne m'aide pas.
        </p>
        <GiphyEmbed 
          src="https://giphy.com/embed/9WHE2bo5Na9Gg"
          title="Flowgraines Bruce Lee"
          width="480"
          height="270"
        />

        <p><br /><br /><br /><br />
        Bref, en fait, il n'y a à culpabiliser pour rien. <br /><br />L'objectif est juste d'avoir installé en soi un espace où je sais que je suis là, je suis présent : ainsi, je ne choisis pas forcément quelque chose par défaut.</p>

        <GiphyEmbed 
          src="https://giphy.com/embed/2wX1HMolIsEsdCyZru"
          title="Flowgraines The good place Chidi"
          width="480"
          height="270"
        />

        <p><br /><br /><br /><br />
        L'ennuie vient en général quand on a épuisé l'ensemble des habitudes (petites ou grandes addictions, ou juste confort occasionnel) qui me permettent de m'ignorer. Ignorer les grandes idées ou bien les petits pas que je n'ai pas suivis par crainte de réussir ou d'échouer. 
        <br /><br /><br />
        </p>

        <div className="video-container">
          <iframe
            src="https://www.youtube.com/embed/pnw2ucpxH0Y?rel=0" 
            frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            title="lâcher-prise et présence à soi">
          </iframe>
        </div>

        <p><br /><br /></p>
        <CheckpointButton coursId="Cours1" chapitreId="chapitre7" checkpointId="checkpoint1" />
        <p> <br /> </p>

        <div className="button">
          <Link to="/formationSaimer" className="sommaire-button">Retour au sommaire</Link>
        </div>
      </>
  );
}

export default Chapitre7Saimer;
