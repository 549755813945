import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { animated } from '@react-spring/web';
import useAnimatedLine from './animations/animatedLine.js';
import { useAuth } from './AuthContext.js';

const FormationSaimer = () => {
  const navigate = useNavigate();
  const activeRef = useRef(null);

  const { userData } = useAuth();
  
  const [animationStyle1, ref1] = useAnimatedLine();

  const handleClick = (path, event) => {
    if (activeRef.current) {
      activeRef.current.classList.remove('active'); // Retirer la classe 'active' de l'ancien élément
    }

    const li = event.currentTarget;
    li.classList.add('active'); // Ajouter la classe 'active' à l'élément cliqué
    activeRef.current = li; // Mettre à jour la référence

    setTimeout(() => {
      navigate(path);
    }, 620); // Naviguer après la transition
  };

  useEffect(() => {
    const elements = document.querySelectorAll(".p_transi");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    elements.forEach((el) => observer.observe(el));

    return () => observer.disconnect();
  }, []);

  return (
      <>
        <p className='p_transi'>Bienvenue <span className='gold'>{userData?.prenom ? userData.prenom : ''}</span> dans le cours</p>
        <animated.div className="animated-line" style={animationStyle1} ref={ref1}>
          <h1 className="animatable">Notre croissance et nos défis de vie !</h1>
          <br />
          <img className="img_fleche" src="./pictures/fleche.png" alt="suivant cours développement personnel" />         
        </animated.div>

        <p style={{ textAlign: 'center' }}>
          <br/>
          À travers chaque chapitre, tu vas apprendre à te libérer de blocages qui t'empêchent d'avancer et à adopter de nouvelles perspectives pour progresser sereinement. Prêt à passer à l'action et à transformer tes défis en opportunités ?
          Tu es capable de grandes choses, et ce cours est là pour t'aider à révéler ton potentiel en te débloquant de schémas de pensées néfastes.
        </p>
        
        <h1>Plan du cours :</h1>
        <ul>
          <li onClick={(e) => handleClick('/formationCroissance/chapitre1', e)}>Chapitre 1 : Positivité consciente</li>
          <li onClick={(e) => handleClick('/formationCroissance/chapitre2', e)}>Chapitre 2 : Équilibre gratitude et désir (ambition)</li>
          <li onClick={(e) => handleClick('/formationCroissance/chapitre3', e)}>Chapitre 3 : Entre confort et inconfort, et liberté</li>
          <li onClick={(e) => handleClick('/formationCroissance/chapitre4', e)}>Chapitre 4 : Accepter l'échec</li>
          <li onClick={(e) => handleClick('/formationCroissance/chapitre5', e)}>Chapitre 5 : Être présent avec l'espace qu'on crée en soi-même</li>
        </ul>

        <div style={{ marginTop: '8vh' }} />
      </>
  );
}

export default FormationSaimer;
