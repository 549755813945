import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from './AuthContext.js';
import { programmes, courseToBundleMap } from './metaprogrammes.js';
import { checkpointDetails } from './checkpointDetails.js';

const Profile = () => {
    const { currentUser, userData, loading } = useAuth();
    const navigate = useNavigate();

    const [hoveredCheckpoint, setHoveredCheckpoint] = useState(null);
    const containerRefs = useRef({}); // Références aux conteneurs
    const [openCourses, setOpenCourses] = useState({}); // État pour gérer l'ouverture des cours
    const chapterRefs = useRef({}); // Références pour les conteneurs des chapitres

    const toggleCourse = (coursId) => {
        setOpenCourses((prev) => {
            const isOpen = !prev[coursId];
            const container = chapterRefs.current[coursId];
    
            if (container) {
                if (isOpen) {
                    // Définit la hauteur dynamique pour l'ouverture
                    console.log('scrollHeight:', container.scrollHeight);

                    container.style.height = `${container.scrollHeight}px`;
    
                    // Réinitialise à "auto" une fois la transition terminée
                    container.addEventListener(
                        'transitionend',
                        () => {
                            container.style.height = 'auto';
                        },
                        { once: true }
                    );
                } else {
                    // Définit la hauteur avant de fermer
                    container.style.height = `${container.scrollHeight}px`;
                    requestAnimationFrame(() => {
                        container.style.height = '0px';
                    });
                }
            }
    
            return { ...prev, [coursId]: isOpen };
        });
    };    
  
    const handleMouseEnter = (coursId, chapitreId, checkpointId) => {
        const uniqueKey = `${coursId}-${chapitreId}-${checkpointId}`;
        setHoveredCheckpoint({ coursId, chapitreId, checkpointId });

        // Calcul dynamique de la hauteur
        const element = containerRefs.current[uniqueKey];
        if (element) {
            element.style.height = `${element.scrollHeight}px`; // Hauteur du contenu réel
        }
    };

    const handleMouseLeave = (coursId, chapitreId, checkpointId) => {
        const uniqueKey = `${coursId}-${chapitreId}-${checkpointId}`;
        setHoveredCheckpoint(null);

        // Réinitialiser la hauteur
        const element = containerRefs.current[uniqueKey];
        if (element) {
            element.style.height = `0px`; // Hauteur réduite à 0
        }
    };

    useEffect(() => {
        if (!currentUser) {
            navigate('/'); // Redirige si l'utilisateur n'est pas connecté
        }
    }, [currentUser, navigate]);

    if (loading) {
        return <><p>Chargement...</p></>;
    }

    if (!userData) {
        return <><p>Aucune donnée utilisateur disponible. Veuillez vérifier votre connexion ou valider votre compte (lien de vérification par e-mail).</p></>;
    }

    const purchasedCourses = programmes.filter(programme =>
        userData.coursAchetes.includes(courseToBundleMap[programme.id])
    );

    return (
        <>
            <div className="profile-container">
                <h1>Mon Profil</h1>
                <div className="profile-info">
                    <p><strong>Prénom :</strong> {userData.prenom || 'Non renseigné'}</p>
                    <p><strong>Nom :</strong> {userData.nom || 'Non renseigné'}</p>
                    <p><strong>Email :</strong> {userData.email}</p>

                    <p>
                    <strong>Statut du compte :</strong> {userData.isSubscribed ? 'Abonné(e)' : 'Non abonné'}
                    <br /><br />
                    </p>
                    {/* Affiche le lien pour gérer les abonnements uniquement si abonné */}
                    {userData.isSubscribed && (
                    <a
                        href={process.env.REACT_APP_GESTION_ABO_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="manage-subscription-link"
                    >
                        Gérer mes abonnements
                    </a>
                    )}
                </div>

                <div className="profile-courses">
                    <h2>Mes cours</h2>
                    {userData.isSubscribed ? (
                        <p>Abonné(e) à tous les cours.</p>
                    ) : purchasedCourses.length > 0 ? (
                        <ul>
                        {purchasedCourses.map((programme) => (
                            <li key={programme.id}>
                            <h3>{programme.title}</h3>
                            <p>{programme.description[0]}</p>
                            </li>
                        ))}
                        </ul>
                    ) : (
                        <p>Aucun cours.</p>
                    )}
                    <Link to="/cours">Accéder aux cours</Link>
                </div>

                <div className="profile-progress">
                    <h2>Progression des cours</h2>
                    <div className="progress-items">
                        {userData.progress &&
                            Object.keys(userData.progress).map((coursId) => {
                                const programme = programmes.find((p) => p.id === coursId);
                                const programmeTitle = programme?.title.replace('Flowgraine', '').trim();

                                return (
                                    <div key={coursId} className="course-progress">
                                        <h2 
                                            className={`course-title ${openCourses[coursId] ? 'open' : ''}`} 
                                            onClick={() => toggleCourse(coursId)} 
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <i 
                                                className={`fas fa-chevron-right chevron-icon`}
                                            ></i>
                                            {` Cours : ${programmeTitle}`}
                                        </h2>
                                        <div 
                                            ref={(el) => (chapterRefs.current[coursId] = el)}
                                            className="chapters-container"
                                        >
                                        {Object.keys(userData.progress[coursId])
                                                .sort((a, b) => {
                                                    const numA = parseInt(a.match(/chapitre(\d+)/i)?.[1], 10) || 0;
                                                    const numB = parseInt(b.match(/chapitre(\d+)/i)?.[1], 10) || 0;
                                                    return numA - numB;
                                                })
                                                .map((chapitreId) => (
                                                    <div key={chapitreId} className="progress-item">
                                                        <ul>
                                                            {Object.keys(userData.progress[coursId][chapitreId]).map((checkpointId) => {
                                                                const uniqueKey = `${coursId}-${chapitreId}-${checkpointId}`;
                                                                const checkpointData =
                                                                    userData.progress[coursId][chapitreId][checkpointId];
                                                                const details =
                                                                    checkpointDetails[coursId]?.[chapitreId]?.[checkpointId];

                                                                return (
                                                                    <li
                                                                        key={uniqueKey}
                                                                        className={`checkpoint-item ${
                                                                            hoveredCheckpoint &&
                                                                            hoveredCheckpoint.coursId === coursId &&
                                                                            hoveredCheckpoint.chapitreId === chapitreId &&
                                                                            hoveredCheckpoint.checkpointId === checkpointId
                                                                                ? 'open'
                                                                                : ''
                                                                        }`}
                                                                        onMouseEnter={() =>
                                                                            handleMouseEnter(coursId, chapitreId, checkpointId)
                                                                        }
                                                                        onMouseLeave={() =>
                                                                            handleMouseLeave(coursId, chapitreId, checkpointId)
                                                                        }
                                                                    >
                                                                        <span>{`${`${chapitreId.replace(/chapitre(\d+)/i, 'Chapitre $1')}`} ${
                                                                            checkpointData ? '🏆 Atteint' : '⏳ Poursuis ton apprentissage ici !'
                                                                        }`}</span>
                                                                        <div
                                                                            className="checkpoint-details"
                                                                            ref={(el) => (containerRefs.current[uniqueKey] = el)}
                                                                        >
                                                                            <h4>{details?.title || ''}</h4>
                                                                            <p>{details?.description || ''}</p>
                                                                            <p>
                                                                                <em>{details?.question || ''}</em>
                                                                            </p>
                                                                        </div>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </div>
                                                ))
                                        }
                                        </div> 
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Profile;
