export const checkpointDetails = {
    Cours1: {
      chapitre1: {
        checkpoint1: {
          title: "Définition de l'amour et équilibre intérieur",
          description: `Vous avez vu les bases avec la définition de l'amour et vos polarités à l'intérieur de vous. Continuez à explorer votre équilibre intérieur pour nourrir votre épanouissement.`,
          question: `Quelles sont vos forces naturelles liées à vos trois facettes intérieures ?`,
        },
      },
      chapitre2: {
        checkpoint1: {
          title: "Création et perception de l'abondance",
          description: `Super ! Vous avez intégré les bases de la création consciente et commencé à reconnaître l'abondance dans votre vie. Continuez à cultiver des pensées positives pour manifester vos désirs.`,
          question: ``,
        },
      },
      chapitre3: {
        checkpoint1: {
          title: "Comprendre l'Ego et l'identité",
          description: `Vous avez exploré ce qu'est l'ego et on a pu identifié ses bienfaits (affirmation de soi, protection) et ses pièges (suridentification, orgueil).`,
          question: `Quel masque social pourriez-vous commencer à lâcher pour être plus aligné avec votre-même ?`,
        },
      },
      chapitre4: {
        checkpoint1: {
          title: "Émotions et empathie",
          description: `Vous avez exploré le rôle des émotions comme des messagers précieux. Vous avez découvert comment cultiver une empathie saine, en ressentant les autres sans perdre votre propre équilibre. Poursuivez cette sagesse émotionnelle !`,
          question: ``,
        },
      },
      chapitre5: {
        checkpoint1: {
          title: "Renforcer l'estime de soi",
          description: `Vous avez exploré les bases de l'estime de soi, en comprenant l'importance de l'auto-validation et de l'acceptation de vos imperfections.
          Continuez à cultiver cette force intérieure !`,
          question: `Comment pourriez-vous aujourd'hui pratiquer l'auto-validation dans une situation concrète ?`,
        },
      },
      chapitre6: {
        checkpoint1: {
          title: "Trouver le sens du bonheur",
          description: `Vous avez appris que le bonheur authentique naît de la reconnexion à soi et de l'équilibre entre l'amour de soi et le partage avec les autres. Vous comprenez que pour aider les autres, il est essentiel de s'aider soi-même, et que la gratitude, l'accueil des émotions, et la présence au moment sont des clés essentielles pour cultiver ce bonheur durable.`,
          question: ``,
        },
      },
      chapitre7: {
        checkpoint1: {
          title: "L'ennui comme opportunité",
          description: `Vous avez appris à voir l'ennui comme une opportunité précieuse de reconnexion à votre monde intérieur. Excellent travail ! Vous avez intégré une nouvelle perspective sur l'ennui, le transformant en un outil de réflexion et de créativité. Continuez à explorer cette dynamique pour mieux vous connecter à vous-même.`,
          question: ``,
        },
      },
      chapitre8: {
        checkpoint1: {
          title: "Comprendre la dépression",
          description: `Vous avez découvert que la dépression n'est pas une fin en soi, mais une invitation à lâcher l'ancien pour faire place au renouveau.
          Vous comprenez qu'elle survient souvent à la suite de chocs, de fins non acceptées, ou d'un désalignement profond entre vos aspirations et vos choix de vie.`,
          question: ``,
        },
      },
      chapitre9: {
        checkpoint1: {
          title: "Avancer vers ses rêves",
          description: `Vous avez compris l'importance de ne pas négocier avec vos rêves et de dépasser vos peurs pour avancer vers vos objectifs. Félicitations ! Vous êtes prêt à poursuivre vos rêves avec confiance et détermination. N'hésitez pas à en parler ici et là pour vous sentir soutenu dans votre démarche. (notamment le groupe du cours)`,
          question: `Quelle est une action concrète que vous pourriez entreprendre dès aujourd'hui pour vous rapprocher de l'un de vos rêves ?`,
        },
      },
      chapitre10: {
        checkpoint1: {
          title: "Ajuster son regard",
          description: `Vous avez découvert le pouvoir de votre perception sur votre vie et celle des autres. Bravo ! Vous avez appris à ajuster votre regard pour nourrir une vision constructive et bienveillante de vous-même et des autres.`,
          question: ``,
        },
      },
      chapitre11: {
        checkpoint1: {
          title: "Cultiver le respect de soi et des autres",
          description: `Vous avez exploré l'importance de vous respecter en reconnaissant vos pensées limitantes et en dépassant les masques sociaux.
          Vous comprenez que percevoir la grandeur et la vulnérabilité chez les autres reflète également votre propre puissance intérieure.`,
          question: `Comment pourriez-vous montrer plus de respect envers une partie de vous-même que vous jugez habituellement ?`,
        },
      },
      chapitre12: {
        checkpoint1: {
          title: "Embrasser sa lumière intérieure",
          description: `Vous savez que votre lumière intérieure est précieuse et que vous méritez de vous entourer de personnes qui respectent et encouragent votre épanouissement. Bravo ! Vous êtes prêt à briller et à vivre en accord avec votre essence unique. Continuez à rayonner naturellement pour inspirer et partager autour de vous.`,
          question: ``,
        },
      },
    },
  };
  