import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { animated } from '@react-spring/web';
import useAnimatedLine from '../animations/animatedLine.js';
import useCloudAnimation from '../animations/animatedCloud.js';
import LoadingGif from '../animations/loadingGif.js';
import CheckpointButton from '../CheckpointButton.js';

const CloudComponent = () => {
  const { props, triggerAnimation } = useCloudAnimation();
  const [animationTriggered, setAnimationTriggered] = useState(false);

  // Écoute le scroll pour déclencher l'animation initiale
  useEffect(() => {
    const handleScroll = () => {
      const cloudsContent = document.querySelector('.clouds-content');
      if (cloudsContent.getBoundingClientRect().top < window.innerHeight && !animationTriggered) {
        triggerAnimation();
        setAnimationTriggered(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [triggerAnimation, animationTriggered]);

  // Déclenche l'animation à intervalles après la première activation
  useEffect(() => {
    let interval;
    if (animationTriggered) {
      interval = setInterval(() => {
        triggerAnimation();
      }, 12000); // Ajuste cet intervalle selon tes besoins
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [animationTriggered, triggerAnimation]);

  return <animated.div style={props} className="cloud" />;
};

function Chapitre1Saimer() {

  const [animationStyle2, ref2] = useAnimatedLine();
  const [animationStyle3, ref3] = useAnimatedLine();
  const [animationStyle4, ref4] = useAnimatedLine();
  const [animationStyle5, ref5] = useAnimatedLine();
  const [animationStyle6, ref6] = useAnimatedLine();
  const [animationStyle7, ref7] = useAnimatedLine();
  const [animationStyle8, ref8] = useAnimatedLine();
  const [animationStyle9, ref9] = useAnimatedLine();

  return (
      <>
        <h1 className='chapitre'>Chapitre 1 : À l'intérieur</h1>

        <p className="bold gold">“Ta tâche n'est pas de chercher l'amour, mais simplement de chercher et trouver tous les obstacles que tu as construits contre l'amour.” Djalâl ad-Dîn Rûmî</p>

        <p><br /><br /><br />La croyance la plus libératrice est celle-ci : celle qui dit que nous sommes fondamentalement ce qui nous rend heureux.se, et que nous sommes complet en nous-même.
        <br />Nous avons notre enfant intérieur qui nous enthousiaste de vivre chaque instant et qui nous dirige au travers de <span className="gold">notre spontanéité</span>; 
        Nous avons notre femme intérieure qui accueille la vie, nous donne <span className="gold">un espace de vie où tout semble possible</span>, avec une capacité à rêver, et à être inspiré; 
        Et nous avons notre homme intérieur qui nous donne une capacité de réalisation, <span className="gold">d'incarnation de nos aspirations</span>, ainsi qu'une aisance d'expression.
        <br /><br />
        Si on parle en ces termes, nous avons bien tous ces trois facettes en nous-même, mais nous les exprimons différemment. <br />
        </p>

        <animated.div className="animated-line" style={animationStyle2} ref={ref2}>
          <p className="animatable">
            Nous sommes plus ou moins habitués à incarner chacun de ces trois aspects. 
            <br />Certains seront plus habitués et à l'aise avec le fait d'être masculin, d'autres plus à l'aise avec le fait d'être féminin. Et de même, les côtés "enfant" ou "adulte" peuvent transparaître selon notre personnalité, bien que chacun est sensé grandir avec une certaine maturité tout de même (cela n'enlève pas la personnalité qu'on peut avoir).
          </p>
        </animated.div>

        <LoadingGif
          src="/pictures/moiteacher2.gif"
          placeholder="/pictures/moiteacher3.png"
          alt="cours développement personnel"
        />
        <p>
        Ceci dit, les croyances, qui diront que nous n'avons pas une de ces parts en nous, nous éloigneront de notre capacité à vivre en paix, et à installer un équilibre en nous qui nous permette de jouir de chaque instant d'une manière saine dans nos relations avec les autres.
        <br />
        </p>

        <p>
        Comment reconnecter à ces parts lorsqu'on se sent trop éloigné de notre féminin, masculin, ou encore de notre enfant intérieur :
        </p>
        <animated.div className="animated-line" style={animationStyle3} ref={ref3}>
          <p className="animatable">
          Se reconnecter à <span className="gold">sa polarité féminine :</span> <br /><br />
          Tout ce qui peut t'ouvrir à ce qui est essentiel en toi, t'ouvrir à ton ressenti, ton intuition, te reconnecte à ta polarité féminine. Cela peut se faire par la créativité : laisser libre court à sa spontanéité, s'exprimer créativement. De même, la méditation, s'efforcer à être empathique, à écouter une personne pleinement, imaginer, ou encore prendre soin ... sont des attitudes qui renforcent nos qualités yin (le féminin). 
          </p>
        </animated.div>
        
        <animated.div className="animated-line" style={animationStyle4} ref={ref4}>
          <p className="animatable">
          Se reconnecter à <span className="gold">sa polarité masculine :</span> <br /><br />
          Pour le masculin, on va se pencher sur des activités où il est nécessaire de réaliser des choses : s'activer physiquement, explorer, réaliser un objectif, quel qu'il soit, étape par étape. On peut aussi : se donner le droit (d'une chose dont on sent profondément le besoin de pouvoir faire/être), prendre confiance en installant dans sa vie une consistance dans ses actions (pour son épanouissement...). Cela renforce nos qualités yang (le masculin).
          </p>
        </animated.div>

        <animated.div className="animated-line" style={animationStyle5} ref={ref5}>
          <p className="animatable">
          Se reconnecter à <span className="gold">son enfant intérieur :</span> <br /><br />
          Pour cela, au-delà de la sécurité dont l'enfant à besoin, on aura à favoriser notre spontanéité : prendre plaisir à jouer, à explorer. S'autoriser à casser les codes, "think outside of the box". On aura besoin aussi de se laisser ressentir ce que l'on ressent comme un enfant le fait : rappelez-vous, il peut pleurer un moment et, 10 secondes plus tard, il peut être passé à autre chose, à rigoler. C'est parce qu'il ne résiste pas à l'émotion.
          </p>
        </animated.div>
        
        <p>
        Comment agit un enfant ? Prenez exemple. Selon ce à quoi vous souhaitez vous reconnecter (c'est déjà en vous), vous pouvez pendre exemple sur des personnes qui incarnent cette polarité selon vous.
        Par contre, ne cherchez en aucun cas à fuir qui vous êtes. L'intention ici est de rester naturel et d'embrasser votre propre authenticité. 
        </p>
        
        <p>Il y a un message que j'aime beaucoup dans un livre ("Ami l'enfant des étoiles", de Enrique Barrios). Ce message nous parle de ce qu'est vraiment l'Amour, le voici :</p>

        <div className="clouds-section">
          <div className="clouds-begin"></div>
          <div className="clouds-content">
          {/* nuages */}
          <CloudComponent />

          <animated.div className="animated-line" style={animationStyle6} ref={ref6}>
            <p className="animatable">“ Pourquoi la vie ? Pourquoi la création ?<br /><br />
            Les intellectuels travaillent, cherchent et ne trouvent pas, et comme ils ne trouvent pas, ils inventent des théories, mais l'antique mystère ne se révèle qu'à l'Amour, à la conscience illuminée par l'Amour : privilège des humbles et des naïfs, comme les enfants.
            L'Amour est un ingrédient subtil de la conscience. Il est capable de révéler le sens profond de l'existence.</p>
          </animated.div>

          <animated.div className="animated-line" style={animationStyle7} ref={ref7}>
            <p className="animatable">
              L'Amour est la seule « drogue » qui soit légale.
              Certains se trompent en le cherchant dans l'alcool et d'autres cherchent dans la drogue ce qui produit l'Amour.
              <br />L'Amour est la chose la plus indispensable dans la vie. <br />
              <b>Les sages</b> connaissent ce secret et ne recherchent que l'Amour. Les autres l'ignorent et cherchent à l'extérieur.
              Comment obtenir l'Amour ?
            </p>
          </animated.div>
                
          <animated.div className="animated-line" style={animationStyle8} ref={ref8}>
            <p className="animatable">
              Aucune technique n'est utile car l'Amour n'est pas quelque chose de matériel. Il n'est pas soumis aux lois de la pensée ni à celles de la raison. Ce sont ces dernières qui lui sont soumises.
              <br /><br />Pour obtenir l'Amour, il faut premièrement savoir que l'Amour n'est pas un sentiment mais un être. L'Amour est quelqu'un, un esprit vivant et Royal, qui, lorsqu'il s'éveille en nous, apporte joie de vivre, il apporte tout.<br /><br />
              Comment faire pour qu'il vienne ?
            </p>
          </animated.div>
          
          <animated.div className="animated-line" style={animationStyle9} ref={ref9}>
            <p className="animatable">
              Premièrement, il faut croire en son existence - car on ne peut pas le voir, on peut seulement le ressentir -  (certains l'appellent Dieu). <br /><br />Par la suite, il faut le chercher dans sa demeure intime, c'est-à-dire le cœur.
              Il n'est pas nécessaire de l'appeler, car il est déjà en nous. Il n'est pas nécessaire de le prier de venir. Il faut seulement le laisser sortir, le libérer, s'y abandonner. 
              <br />Il ne s'agit pas de demander l'Amour, mais de donner l'Amour.
              <br /><br />Comment on obtient l'Amour ?<br />
              En donnant de l'Amour.<br /><br />
              En aimant. ”
            </p>
          </animated.div>

          </div>
          <div className="clouds-end"></div>
        </div>
        {/* Fin nuages section */}

        <p><br /></p>

        <p>
        Chercher désespérément l'amour à l'extérieur n'est jamais une solution. C'est une illusion.
        Car l'amour est ce qui se trouve à l'intérieur. <br />Nous sommes l'Amour. Et le fait de le trouver à l'extérieur signifie qu'on le trouve à l'intérieur en même temps. 
        <br /><br /><br /><br />
        </p>
        
        <div className="video-container">
          <iframe
            src="https://www.youtube.com/embed/vvM6xc1BLaA?rel=0" 
            frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            title="intro sur l'amour envers soi et plus">
          </iframe>
        </div>

        <p>
          <br /> 
        </p>
        <CheckpointButton coursId="Cours1" chapitreId="chapitre1" checkpointId="checkpoint1" />
        <p>
          <br /> <br /> <br />
        </p>
        
        <div className="button">
          <Link to="/formationSaimer" className="sommaire-button">Retour au sommaire</Link>
        </div>
      </>
  );
}

export default Chapitre1Saimer;
